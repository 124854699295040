import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "section-md bg-transparent"
};
const _hoisted_2 = {
  class: "container"
};
const _hoisted_3 = {
  class: "row row-25 row-xl-55 justify-content-between flex-md-row-reverse"
};
const _hoisted_4 = {
  class: "col-sm-6 col-lg-7"
};
const _hoisted_5 = {
  class: "text-decorated text-decorated-large"
};
const _hoisted_6 = ["innerHTML"];
import { ref, onMounted, computed } from 'vue';
import { useRoute } from 'vue-router';
import { newsInfo } from '@/api/news';

import { ElMessage } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/message/style/css';
export default {
  __name: 'case-page',
  setup(__props) {
    const route = useRoute();
    const path = computed(() => {
      return process.env.VUE_APP_BASE_ADDRESS;
    });
    const info = ref(null);
    const getData = async () => {
      if (!route.query.id) {
        return ElMessage.error('参数错误');
      }
      const data = await newsInfo(route.query.id);
      if (data) {
        data.thumb = path.value + data.thumb;
        info.value = data;
      }
    };
    onMounted(() => {
      getData();
    });
    return (_ctx, _cache) => {
      const _component_el_backtop = _resolveComponent("el-backtop");
      const _component_Header = _resolveComponent("Header");
      const _component_Footer = _resolveComponent("Footer");
      const _component_el_scrollbar = _resolveComponent("el-scrollbar");
      return _openBlock(), _createBlock(_component_el_scrollbar, {
        height: "100vh"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_backtop, {
          class: "to-top mdi-chevron-up",
          style: {
            "opacity": "1",
            "transform": "scale(1)"
          },
          target: ".el-scrollbar__wrap",
          "visibility-height": 2000
        }), _createVNode(_component_Header), _createElementVNode("section", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_cache[0] || (_cache[0] = _createElementVNode("div", {
          class: "col-sm-6 col-lg-4 text-lg-right text-center"
        }, [_createElementVNode("div", {
          class: "image-block image-block-left"
        }, [_createElementVNode("img", {
          src: "/images/12.jpg",
          alt: "",
          width: "321",
          height: "333"
        }), _createElementVNode("div", {
          class: "image-block-embed"
        }, [_createElementVNode("img", {
          src: "/images/zixunwechat.jpg",
          alt: "",
          width: "95",
          height: "25"
        }), _createElementVNode("div", {
          class: "image-block-title small text-uppercase"
        }, " Company ")])])], -1)), _createElementVNode("div", _hoisted_4, [_createElementVNode("h3", _hoisted_5, _toDisplayString(info.value?.title), 1), _createElementVNode("div", {
          class: "big",
          innerHTML: info.value?.content
        }, null, 8, _hoisted_6)])])])]), _createVNode(_component_Footer)]),
        _: 1
      });
    };
  }
};