import request from "@/utils/request";

/**
 * 律师详情
 */
export function lawyerInfo(id) {
    return request({
        url: "/api/lawyer/info/" + id,
        method: "get",
    });
}