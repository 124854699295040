import request from "@/utils/request";

/**
 * 专业领域列表
 */
export function territoryList() {
    return request({
        url: "/api/territory/list",
        method: "get",
    });
}

/**
 * 专业领域详情
 */
export function territoryInfo(id) {
    return request({
        url: "/api/territory/info/" + id,
        method: "get",
    });
}