import request from "@/utils/request";

/**
 * 关于冀华
 */
export function aboutInfo({type = 'introduction'}) {
    return request({
        url: "/api/about/info?type=" + type,
        method: "get",
    });
}
