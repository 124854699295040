import request from "@/utils/request";

/**
 * 服务单位
 */
export function customerList() {
    return request({
        url: "/api/customer/list",
        method: "get",
    });
}
