import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import teamMember from '../views/team-member.vue';
import contactUs from '../views/contact-us.vue';
import casePage from '../views/case-page.vue';
import lawyers from '../views/lawyers.vue';
import ourCases from '../views/our-cases.vue';
import service from '../views/service.vue';
import territory from '../views/territory.vue';
import about from '../views/about.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    // 律师列表
    path: '/lawyers',
    name: 'lawyers',
    component: lawyers
  },
  {
    // 专业领域
    path: '/territory',
    name: 'territory',
    component: territory
  },
  {
    // 新闻列表
    path: '/our-cases',
    name: 'ourCases',
    component: ourCases
  },
  {
    // 新闻详情
    path: '/case-page',
    name: 'casePage',
    component: casePage
  },
  {
    // 服务单位
    path: '/service',
    name: 'service',
    component: service
  },
  {
    // 律师详情
    path: '/team-member',
    name: 'teamMember',
    component: teamMember
  },
  {
    // 联系我们
    path: '/contact-us',
    name: 'contactUs',
    component: contactUs
  },
  {
    // 关于冀华
    path: '/about',
    name: 'about',
    component: about
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
