import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createStaticVNode as _createStaticVNode } from "vue";
const _hoisted_1 = {
  class: "footer-extended context-dark bg-900"
};
const _hoisted_2 = {
  class: "container"
};
const _hoisted_3 = {
  class: "row row-30"
};
const _hoisted_4 = {
  class: "col-md-8"
};
const _hoisted_5 = {
  class: "row row-10 row-content"
};
const _hoisted_6 = {
  class: "col-12 col-md-6 col-lg-6"
};
const _hoisted_7 = {
  class: "list list-sm"
};
const _hoisted_8 = {
  class: "link link-inherit"
};
const _hoisted_9 = {
  class: "link link-inherit"
};
const _hoisted_10 = {
  class: "col-12 col-md-6 col-lg-6"
};
const _hoisted_11 = {
  class: "list list-sm"
};
const _hoisted_12 = {
  class: "link link-inherit"
};
const _hoisted_13 = {
  class: "link link-inherit"
};
const _hoisted_14 = {
  class: "row row-10 big"
};
const _hoisted_15 = {
  class: "col-lg-6"
};
const _hoisted_16 = {
  class: "term-list term-list-inline"
};
const _hoisted_17 = {
  class: "link link-inherit"
};
const _hoisted_18 = {
  class: "col-lg-6"
};
const _hoisted_19 = {
  class: "group-10 group-x-50 d-flex flex-wrap"
};
const _hoisted_20 = {
  class: "term-list term-list-inline"
};
const _hoisted_21 = {
  class: "link link-inherit"
};
const _hoisted_22 = {
  class: "term-list term-list-inline"
};
const _hoisted_23 = {
  class: "link link-inherit"
};
import { ref, onMounted } from 'vue';
import { addressList } from '@/api/home/index';
export default {
  __name: 'Footer',
  setup(__props) {
    const centerAddress = ref({
      city: '石家庄总部',
      address: '河北省石家庄市桥西区石铜路11号冀华律师楼',
      phone: '0311-85288005',
      email: 'jihua666666@163.com'
    });
    const dataList = ref([]);
    const getData = async () => {
      const data = await addressList();
      centerAddress.value = data.shift();
      dataList.value = data;
    };
    onMounted(() => {
      getData();
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("footer", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_cache[0] || (_cache[0] = _createElementVNode("div", {
        class: "logo footer-logo mt-2"
      }, [_createElementVNode("a", {
        class: "logo-link",
        href: "index.html"
      }, [_createElementVNode("img", {
        class: "logo-image-inverse",
        src: "/images/logo-inverse-290x82.png",
        alt: "Lawex",
        width: "145",
        height: "41"
      })])], -1)), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createElementVNode("ul", _hoisted_7, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dataList.value.slice(0, 3), item => {
        return _openBlock(), _createElementBlock("li", {
          class: "list-item",
          style: {
            "display": "flex",
            "flex-direction": "column"
          },
          key: item.id
        }, [_createElementVNode("span", _hoisted_8, _toDisplayString(item.city) + "：" + _toDisplayString(item.address), 1), _createElementVNode("span", _hoisted_9, "电 话：" + _toDisplayString(item.phone), 1)]);
      }), 128))])]), _createElementVNode("div", _hoisted_10, [_createElementVNode("ul", _hoisted_11, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dataList.value.slice(3, 6), item => {
        return _openBlock(), _createElementBlock("li", {
          class: "list-item",
          style: {
            "display": "flex",
            "flex-direction": "column"
          },
          key: item.id
        }, [_createElementVNode("span", _hoisted_12, _toDisplayString(item.city) + "：" + _toDisplayString(item.address), 1), _createElementVNode("span", _hoisted_13, "电 话：" + _toDisplayString(item.phone), 1)]);
      }), 128))])])])]), _cache[1] || (_cache[1] = _createStaticVNode("<div class=\"col-md-4\" style=\"display:flex;justify-content:center;align-items:center;\"><div class=\"row row-10 row-content\"><div class=\"col-6 col-md-12 col-lg-6\" style=\"display:flex;flex-direction:column;align-items:center;\"><img src=\"/images/official.jpg\" width=\"136\" height=\"136\" alt=\"\"><span>企业公众号</span></div><div class=\"col-6 col-md-12 col-lg-6\" style=\"display:flex;flex-direction:column;align-items:center;\"><img src=\"/images/zixunwechat.jpg\" width=\"136\" height=\"136\" alt=\"\"><span>咨询微信</span></div></div></div>", 1))]), _cache[2] || (_cache[2] = _createElementVNode("div", {
        class: "divider footer-divider footer-divider-1"
      }, null, -1)), _createElementVNode("div", _hoisted_14, [_createElementVNode("div", _hoisted_15, [_createElementVNode("div", _hoisted_16, [_createElementVNode("span", _hoisted_17, _toDisplayString(centerAddress.value.city) + "：" + _toDisplayString(centerAddress.value.address), 1)])]), _createElementVNode("div", _hoisted_18, [_createElementVNode("div", _hoisted_19, [_createElementVNode("div", null, [_createElementVNode("dl", _hoisted_20, [_createElementVNode("span", _hoisted_21, "电话: " + _toDisplayString(centerAddress.value.phone), 1)])]), _createElementVNode("div", null, [_createElementVNode("dl", _hoisted_22, [_createElementVNode("span", _hoisted_23, "邮箱: " + _toDisplayString(centerAddress.value.email), 1)])])])])]), _cache[3] || (_cache[3] = _createStaticVNode("<div class=\"divider footer-divider\"></div><div class=\"row row-15\"><div class=\"col-sm-12 col-lg-12\"><div class=\"group-10 group-x-xl-40\" style=\"text-align:center;\"><p class=\"rights\"><span>Copyright ©2024冀华律师事务所 冀ICP备18004099号-7</span></p></div></div></div>", 2))])]);
    };
  }
};