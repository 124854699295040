import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "section-md bg-100"
};
const _hoisted_2 = {
  class: "container"
};
const _hoisted_3 = {
  class: "row row-30 row-xl-55 justify-content-between align-items-center"
};
const _hoisted_4 = {
  class: "col-sm-5 col-md-4"
};
const _hoisted_5 = {
  class: "blurb blurb-info"
};
const _hoisted_6 = {
  class: "blurb-embed"
};
const _hoisted_7 = {
  class: "blurb blurb-info text-dark"
};
const _hoisted_8 = {
  class: "blurb-embed"
};
const _hoisted_9 = {
  class: "link link-inherit big2",
  href: "tel:#"
};
const _hoisted_10 = {
  class: "blurb blurb-info"
};
const _hoisted_11 = {
  class: "blurb-embed"
};
const _hoisted_12 = {
  href: "mailto:#"
};
import { ref, onMounted } from 'vue';
import { addressList as addressListHttp } from '@/api/home';

// 总部

export default {
  __name: 'contact-us',
  setup(__props) {
    const centerInfo = ref([{
      city: '石家庄总部',
      address: '河北省石家庄市桥西区石铜路11号冀华律师楼',
      phone: '0311-85288005',
      email: 'jihua666666@163.com'
    }]);
    const getAddress = async () => {
      const data = await addressListHttp({
        city: '石家庄'
      });
      if (data?.length > 0) {
        centerInfo.value = data[0];
      }
    };
    onMounted(() => {
      getAddress();
    });
    return (_ctx, _cache) => {
      const _component_Header = _resolveComponent("Header");
      const _component_Footer = _resolveComponent("Footer");
      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_Header, {
        bg: "/images/background/bg-2-1920x496.jpg",
        title: "联系我们"
      }), _createElementVNode("section", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_cache[1] || (_cache[1] = _createElementVNode("div", {
        class: "blurb-header"
      }, [_createElementVNode("div", {
        class: "blurb-icon linearicons-map2"
      }), _createElementVNode("div", {
        class: "blurb-title h4"
      }, "地址")], -1)), _createElementVNode("div", _hoisted_6, [_createElementVNode("h5", null, [_createTextVNode(_toDisplayString(centerInfo.value.address?.slice(0, 7)) + " ", 1), _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)), _createTextVNode(_toDisplayString(centerInfo.value.address?.slice(7)), 1)])])]), _createElementVNode("div", _hoisted_7, [_cache[2] || (_cache[2] = _createElementVNode("div", {
        class: "blurb-header"
      }, [_createElementVNode("div", {
        class: "blurb-icon linearicons-smartphone"
      }), _createElementVNode("div", {
        class: "blurb-title h4"
      }, "电话")], -1)), _createElementVNode("div", _hoisted_8, [_createElementVNode("a", _hoisted_9, _toDisplayString(centerInfo.value?.phone), 1)])]), _createElementVNode("div", _hoisted_10, [_cache[3] || (_cache[3] = _createElementVNode("div", {
        class: "blurb-header"
      }, [_createElementVNode("div", {
        class: "blurb-icon linearicons-envelope-open"
      }), _createElementVNode("div", {
        class: "blurb-title h4"
      }, "邮箱")], -1)), _createElementVNode("div", _hoisted_11, [_createElementVNode("h5", null, [_createElementVNode("a", _hoisted_12, _toDisplayString(centerInfo.value?.email), 1)])])])]), _cache[4] || (_cache[4] = _createElementVNode("div", {
        class: "col-sm-7 col-md-8"
      }, [_createElementVNode("img", {
        src: "/images/map.png",
        alt: "",
        width: "100%",
        height: "400"
      })], -1))])])]), _createVNode(_component_Footer)]);
    };
  }
};