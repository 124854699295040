import axios from "axios";
import cache from "@/utils/cache";
import { queryParams } from "@/utils/index";
import { ElMessage } from "element-plus";

// 创建 axios 实例
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 50000,
    headers: { "Content-Type": "application/json;charset=utf-8" },
});

// 请求拦截器
service.interceptors.request.use(
    (config) => {
        const accessToken = cache("accessToken");
        if (accessToken) {
            config.headers.Authorization = accessToken;
        }
        // if (config.method === "get" && config.data !== undefined) {
        //     const origainData = JSON.parse(JSON.stringify(config.data));

        //     const query = queryParams(origainData, false);

        //     config.url += config.url.includes('?') ? `${query}` : `?${query}`;
        // }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// 响应拦截器
service.interceptors.response.use(
   async (response) => {
        // http状态码2xx 和response.data.status（后台自己定义的码） 不一样
        const { status, message, data } = response.data;
        // console.log('响应拦截器', status, message, data);

        if (status === 200) {
            return data;
        }

        // 响应数据为二进制流处理(Excel导出)
        if (response.data instanceof ArrayBuffer) {
            return response;
        }

        // token 过期,重新登录
        if (status === 99999 || status === 401) {
            ElMessage.error(message || "系统出错");
            setTimeout(() => {
                location.reload();
            }, 500);
            return;
        }

        ElMessage.error(message || "系统出错");

        return Promise.reject(new Error(message || "Error"));
    },
    (error) => {
        // http状态码2xx以外
        if (error?.response?.data) {
            const { status, message } = error.response.data;

            ElMessage.error(message || "系统出错");
        }
        return Promise.reject(error?.response?.data || error, error?.response || error);
    }
);

// 导出 axios 实例
export default service;
