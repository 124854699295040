import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "section-md bg-transparent"
};
const _hoisted_2 = {
  class: "container"
};
const _hoisted_3 = {
  class: "row row-30 row-xl-20 row-xxl-100"
};
const _hoisted_4 = {
  class: "post post-shadow"
};
const _hoisted_5 = {
  class: "post-media"
};
const _hoisted_6 = ["src"];
const _hoisted_7 = {
  class: "post-content"
};
const _hoisted_8 = {
  class: "post-tags group-5"
};
const _hoisted_9 = {
  class: "post-tag"
};
const _hoisted_10 = {
  class: "post-title text-divider"
};
const _hoisted_11 = {
  href: "#"
};
const _hoisted_12 = {
  class: "post-date"
};
const _hoisted_13 = {
  key: 0,
  class: "text-primary text-center loading-indicator"
};
const _hoisted_14 = {
  key: 1,
  class: "text-primary text-center no-more-data"
};
import { onMounted, ref, computed, nextTick } from 'vue';
import { newsList as newsListHttp } from '@/api/news';
import { useThrottleFn } from '@vueuse/core';
import dayjs from 'dayjs';
export default {
  __name: 'our-cases',
  setup(__props) {
    const path = computed(() => {
      return process.env.VUE_APP_BASE_ADDRESS;
    });

    // 新闻
    const newsList = ref([]);
    const loading = ref(false); // 加载状态标志
    const noMoreData = ref(false); // 是否没有更多数据

    const getData = async (page = 1) => {
      if (loading.value || noMoreData.value) return; // 如果已经在加载中，直接返回
      loading.value = true; // 设置加载状态为 true
      try {
        const {
          list,
          total
        } = await newsListHttp({
          page,
          pageSize: 20
        });
        // 没有更多数据了
        noMoreData.value = list.length < 20;
        if (list?.length > 0) {
          for (const item of list) {
            if (!item?.thumb) continue;
            if (!item.thumb.startsWith('http')) item.thumb = path.value + item.thumb;
          }
          newsList.value.push(...list);
        }
      } catch (error) {
        console.error('加载数据失败:', error);
      } finally {
        loading.value = false;
      }
    };
    const scrollbarRef = ref(null);
    const scrollFn = useThrottleFn(e => {
      const {
        clientHeight,
        scrollHeight,
        scrollTop
      } = scrollbarRef.value?.wrapRef;
      // console.log(scrollHeight, clientHeight, scrollTop, '***clientHeight***');
      if (scrollbarRef.value && !loading.value && !noMoreData.value) {
        // 计算距离底部的距离
        const distanceToBottom = scrollHeight - clientHeight - scrollTop;
        // console.log(distanceToBottom, '***计算距离底部的距离***');
        if (distanceToBottom <= 1500) {
          // console.log('触发加载更多数据', newsList.value.length, Math.ceil(newsList.value.length) / 20);
          // 触发加载更多数据的函数
          getData(Math.ceil(newsList.value.length) / 20 + 1);
        }
      }
      // 获取滚动条高度
      // console.log(e, '***5566665***', e.scrollTop);
    });
    onMounted(async () => {
      nextTick(() => {
        getData();
      });
    });
    return (_ctx, _cache) => {
      const _component_el_backtop = _resolveComponent("el-backtop");
      const _component_Header = _resolveComponent("Header");
      const _component_router_link = _resolveComponent("router-link");
      const _component_Footer = _resolveComponent("Footer");
      const _component_el_scrollbar = _resolveComponent("el-scrollbar");
      return _openBlock(), _createBlock(_component_el_scrollbar, {
        ref_key: "scrollbarRef",
        ref: scrollbarRef,
        height: "100vh",
        onScroll: _unref(scrollFn)
      }, {
        default: _withCtx(() => [_createVNode(_component_el_backtop, {
          class: "to-top mdi-chevron-up",
          style: {
            "opacity": "1",
            "transform": "scale(1)"
          },
          target: ".el-scrollbar__wrap",
          "visibility-height": 500
        }), _createVNode(_component_Header, {
          title: "冀华观点"
        }), _createElementVNode("section", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(newsList.value, item => {
          return _openBlock(), _createElementBlock("div", {
            class: "col-md-4",
            key: item.id
          }, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_router_link, {
            to: '/case-page?id=' + item.id
          }, {
            default: _withCtx(() => [_createElementVNode("div", _hoisted_5, [_createElementVNode("img", {
              src: item.thumb,
              alt: "",
              style: {
                "object-fit": "contain",
                "width": "370px",
                "height": "257px"
              }
            }, null, 8, _hoisted_6), _cache[0] || (_cache[0] = _createElementVNode("div", {
              class: "post-hover-btn"
            }, "详情", -1))]), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, [_createElementVNode("span", _hoisted_9, _toDisplayString(item?.news_type?.name), 1)]), _createElementVNode("h5", _hoisted_10, [_createElementVNode("a", _hoisted_11, _toDisplayString(item.title), 1)]), _createElementVNode("h5", _hoisted_12, _toDisplayString(_unref(dayjs)(item.date).format('YYYY-MM-DD')), 1)])]),
            _: 2
          }, 1032, ["to"])])]);
        }), 128))])])]), loading.value ? (_openBlock(), _createElementBlock("div", _hoisted_13, "加载中...")) : _createCommentVNode("", true), noMoreData.value ? (_openBlock(), _createElementBlock("div", _hoisted_14, "没有更多数据了")) : _createCommentVNode("", true), _createVNode(_component_Footer)]),
        _: 1
      }, 8, ["onScroll"]);
    };
  }
};