import request from "@/utils/request";

/**
 * 新闻列表
 */
export function newsList(params) {
    return request({
        url: "/api/news/listByPage",
        method: "get",
        params
    });
}

/**
 * 新闻详情
 */
export function newsInfo(id) {
    return request({
        url: "/api/news/info/" + id,
        method: "get",
    });
}
