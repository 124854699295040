import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  class: "section-md bg-transparent"
};
const _hoisted_2 = {
  class: "container"
};
const _hoisted_3 = {
  class: "row row-40 row-xl-55 row-xxl-100"
};
const _hoisted_4 = {
  class: "thumbnail"
};
const _hoisted_5 = ["src"];
import { ref, onMounted, computed } from 'vue';
import { customerList } from '@/api/customer';
export default {
  __name: 'service',
  setup(__props) {
    const path = computed(() => {
      return process.env.VUE_APP_BASE_ADDRESS;
    });
    const list = ref([]);
    const getData = params => {
      customerList().then(res => {
        for (const item of res) {
          item.src = path.value + item.src;
        }
        list.value = res;
      });
    };
    onMounted(() => {
      getData();
    });
    return (_ctx, _cache) => {
      const _component_Header = _resolveComponent("Header");
      const _component_Footer = _resolveComponent("Footer");
      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_Header, {
        title: "专业领域"
      }), _createElementVNode("section", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(list.value, item => {
        return _openBlock(), _createElementBlock("div", {
          class: "col-sm-6 col-lg-4",
          key: item.id
        }, [_createElementVNode("div", _hoisted_4, [_createElementVNode("img", {
          src: item.src,
          alt: "",
          width: "270"
        }, null, 8, _hoisted_5)])]);
      }), 128))])])]), _createVNode(_component_Footer)]);
    };
  }
};