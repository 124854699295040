import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "section-md bg-transparent",
  style: {
    "opacity": "1"
  }
};
const _hoisted_2 = {
  class: "container"
};
const _hoisted_3 = {
  class: "row row-30 row-xl-55 row-xxl-100 justify-content-center"
};
const _hoisted_4 = {
  class: "col-md-8 row"
};
const _hoisted_5 = ["src"];
const _hoisted_6 = ["innerHTML"];
const _hoisted_7 = {
  class: "col-md-3 col-xl-3"
};
const _hoisted_8 = {
  class: "list list-divided list-divided-sm big"
};
const _hoisted_9 = {
  class: "list-item"
};
const _hoisted_10 = {
  class: "list-item"
};
const _hoisted_11 = {
  class: "list-item"
};
const _hoisted_12 = {
  class: "list-item"
};
import { ref, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import { aboutInfo } from '@/api/about';
export default {
  __name: 'about',
  setup(__props) {
    const route = useRoute();
    const typeQuery = ref('introduction');
    const info = ref(null);
    const honourList = ref([]);
    const scrollbarRef = ref(null);
    watch(() => route.query.type, () => {
      getData();
    }, {
      deep: true
    });
    const getData = async typeSrc => {
      const type = typeSrc ? typeSrc : route?.query?.type || 'introduction';
      typeQuery.value = type;
      scrollbarRef.value?.setScrollTop(0);
      const data = await aboutInfo({
        type
      });
      if (type === 'honour') {
        honourList.value = data;
      } else {
        info.value = data;
      }
    };
    onMounted(async () => {
      getData();
    });
    return (_ctx, _cache) => {
      const _component_el_backtop = _resolveComponent("el-backtop");
      const _component_Header = _resolveComponent("Header");
      const _component_router_link = _resolveComponent("router-link");
      const _component_Footer = _resolveComponent("Footer");
      const _component_el_scrollbar = _resolveComponent("el-scrollbar");
      return _openBlock(), _createBlock(_component_el_scrollbar, {
        ref_key: "scrollbarRef",
        ref: scrollbarRef,
        height: "100vh"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_backtop, {
          class: "to-top mdi-chevron-up",
          style: {
            "opacity": "1",
            "transform": "scale(1)"
          },
          target: ".el-scrollbar__wrap",
          "visibility-height": 500
        }), _createVNode(_component_Header, {
          title: "关于冀华"
        }), _createElementVNode("section", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [typeQuery.value === 'honour' ? (_openBlock(true), _createElementBlock(_Fragment, {
          key: 0
        }, _renderList(honourList.value, (item, idx) => {
          return _openBlock(), _createElementBlock("div", {
            class: "col-md-4",
            style: {
              "display": "flex",
              "justify-content": "center",
              "align-items": "center",
              "margin-bottom": "10px"
            },
            key: idx
          }, [_createElementVNode("img", {
            src: item,
            alt: "",
            width: "270"
          }, null, 8, _hoisted_5)]);
        }), 128)) : (_openBlock(), _createElementBlock("h5", {
          key: 1,
          innerHTML: info.value
        }, null, 8, _hoisted_6))]), _cache[6] || (_cache[6] = _createElementVNode("div", {
          class: "col-md-1"
        }, null, -1)), _createElementVNode("div", _hoisted_7, [_cache[5] || (_cache[5] = _createElementVNode("h3", {
          class: "text-primary"
        }, "关于冀华", -1)), _createElementVNode("ul", _hoisted_8, [_createElementVNode("li", _hoisted_9, [_createElementVNode("a", {
          class: _normalizeClass(["link link-arrow", typeQuery.value == 'introduction' ? 'link-hover' : '']),
          onClick: _cache[0] || (_cache[0] = $event => getData('introduction'))
        }, "冀华简介", 2)]), _createElementVNode("li", _hoisted_10, [_createElementVNode("a", {
          class: _normalizeClass(["link link-arrow", typeQuery.value == 'organization' ? 'link-hover' : '']),
          onClick: _cache[1] || (_cache[1] = $event => getData('organization'))
        }, "组织结构", 2)]), _createElementVNode("li", _hoisted_11, [_createElementVNode("a", {
          class: _normalizeClass(["link link-arrow", typeQuery.value == 'honour' ? 'link-hover' : '']),
          onClick: _cache[2] || (_cache[2] = $event => getData('honour'))
        }, "资质荣誉", 2)]), _createElementVNode("li", _hoisted_12, [_createElementVNode("a", {
          class: _normalizeClass(["link link-arrow", typeQuery.value == 'service' ? 'link-hover' : '']),
          onClick: _cache[3] || (_cache[3] = $event => getData('service'))
        }, "服务理念", 2)])]), _createVNode(_component_router_link, {
          class: "btn btn-block",
          to: "/contact-us"
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [_createTextVNode("联系我们")])),
          _: 1
        })])])])]), _createVNode(_component_Footer)]),
        _: 1
      }, 512);
    };
  }
};