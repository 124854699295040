import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "section-md bg-transparent"
};
const _hoisted_2 = {
  class: "container"
};
const _hoisted_3 = {
  class: "row row-30 row-xl-55 row-xxl-100 justify-content-between"
};
const _hoisted_4 = {
  class: "col-md-8"
};
const _hoisted_5 = ["innerHTML"];
const _hoisted_6 = {
  class: "col-md-4 col-xl-3"
};
const _hoisted_7 = {
  class: "list list-divided list-divided-sm big"
};
const _hoisted_8 = ["onClick"];
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { territoryInfo, territoryList as territoryListHttp } from '@/api/territory';

import { ElMessage } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/message/style/css';
export default {
  __name: 'territory',
  setup(__props) {
    const route = useRoute();
    const info = ref(null);
    const scrollbarRef = ref(null);
    const territoryIdFromUrl = ref(null);
    const getData = async (id = '') => {
      const territoryId = id || route.query.id;
      territoryIdFromUrl.value = territoryId;
      if (!territoryId) {
        return ElMessage.error('参数错误');
      }
      const data = await territoryInfo(territoryId);
      scrollbarRef.value?.setScrollTop(0);
      info.value = data;
    };

    // 专业领域
    const territoryList = ref([]);
    const getTerritory = async () => {
      return territoryListHttp().then(res => {
        territoryList.value = res || [];
        return res;
      });
    };
    onMounted(async () => {
      await getTerritory().then(res => {
        if (!route.query?.id) {
          getData(res[0].id);
        } else {
          getData();
        }
      });
    });
    return (_ctx, _cache) => {
      const _component_Header = _resolveComponent("Header");
      const _component_router_link = _resolveComponent("router-link");
      const _component_Footer = _resolveComponent("Footer");
      const _component_el_scrollbar = _resolveComponent("el-scrollbar");
      return _openBlock(), _createBlock(_component_el_scrollbar, {
        ref_key: "scrollbarRef",
        ref: scrollbarRef,
        height: "100vh"
      }, {
        default: _withCtx(() => [_createVNode(_component_Header, {
          title: "专业领域"
        }), _createElementVNode("section", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("h3", null, _toDisplayString(info.value?.name), 1), _createElementVNode("h5", {
          innerHTML: info.value?.content
        }, null, 8, _hoisted_5)]), _createElementVNode("div", _hoisted_6, [_cache[1] || (_cache[1] = _createElementVNode("h3", {
          class: "text-primary"
        }, "专业领域", -1)), _createElementVNode("ul", _hoisted_7, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(territoryList.value, item => {
          return _openBlock(), _createElementBlock("li", {
            class: "list-item",
            key: item.id
          }, [_createElementVNode("a", {
            class: _normalizeClass(["link link-arrow", territoryIdFromUrl.value == item.id ? 'link-hover' : '']),
            onClick: $event => getData(item.id)
          }, _toDisplayString(item.name), 11, _hoisted_8)]);
        }), 128))]), _createVNode(_component_router_link, {
          class: "btn btn-block",
          to: "/contact-us"
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [_createTextVNode("联系我们")])),
          _: 1
        })])])])]), _createVNode(_component_Footer)]),
        _: 1
      }, 512);
    };
  }
};