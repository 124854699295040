import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, unref as _unref, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "section-md"
};
const _hoisted_2 = {
  class: "container"
};
const _hoisted_3 = {
  class: "row row-5 row-gutters-5 row-offset-lg person-poster-container"
};
const _hoisted_4 = {
  class: "person person-boxed",
  "data-animate": " {\"class\":\"fadeInUpBig\"}"
};
const _hoisted_5 = ["src"];
const _hoisted_6 = {
  class: "person-inner"
};
const _hoisted_7 = {
  class: "person-inner-item person-content"
};
const _hoisted_8 = {
  class: "person-title text-primary"
};
const _hoisted_9 = {
  class: "text-primary"
};
const _hoisted_10 = {
  class: "person-inner-item"
};
const _hoisted_11 = {
  key: 0,
  class: "text-primary text-center loading-indicator"
};
const _hoisted_12 = {
  key: 1,
  class: "text-primary text-center no-more-data"
};
import { onMounted, ref, computed, nextTick } from 'vue';
import { lawyersList as lawyerListHttp } from '@/api/home/index';
import { useThrottleFn } from '@vueuse/core';
export default {
  __name: 'lawyers',
  setup(__props) {
    const path = computed(() => {
      return process.env.VUE_APP_BASE_ADDRESS;
    });

    // 律师
    const lawyerList = ref([]);
    const loading = ref(false); // 加载状态标志
    const noMoreData = ref(false); // 是否没有更多数据

    const getData = async (page = 1) => {
      if (loading.value || noMoreData.value) return; // 如果已经在加载中，直接返回
      loading.value = true; // 设置加载状态为 true
      try {
        const {
          list,
          total
        } = await lawyerListHttp({
          page,
          pageSize: 20
        });
        if (list?.length > 0) {
          for (const item of list) {
            if (!item.avatar.startsWith('http')) item.avatar = path.value + item.avatar;
          }
          // 没有更多数据了
          noMoreData.value = list.length < 20;
          lawyerList.value.push(...list);
        }
      } catch (error) {
        console.error('加载数据失败:', error);
      } finally {
        loading.value = false;
      }
    };
    const scrollbarRef = ref(null);
    const scrollFn = useThrottleFn(e => {
      const {
        clientHeight,
        scrollHeight,
        scrollTop
      } = scrollbarRef.value?.wrapRef;
      if (scrollbarRef.value && !loading.value && !noMoreData.value) {
        // 计算距离底部的距离
        const distanceToBottom = scrollHeight - clientHeight - scrollTop;
        // console.log(distanceToBottom, '***计算距离底部的距离***');
        if (distanceToBottom <= 1500) {
          // 触发加载更多数据的函数
          getData(Math.ceil(lawyerList.value.length) / 20 + 1);
        }
      }
      // 获取滚动条高度
      // console.log(e, '***5566665***', e.scrollTop);
    });
    onMounted(async () => {
      nextTick(() => {
        getData();
      });
    });
    return (_ctx, _cache) => {
      const _component_el_backtop = _resolveComponent("el-backtop");
      const _component_Header = _resolveComponent("Header");
      const _component_router_link = _resolveComponent("router-link");
      const _component_Footer = _resolveComponent("Footer");
      const _component_el_scrollbar = _resolveComponent("el-scrollbar");
      return _openBlock(), _createBlock(_component_el_scrollbar, {
        ref_key: "scrollbarRef",
        ref: scrollbarRef,
        height: "100vh",
        onScroll: _unref(scrollFn)
      }, {
        default: _withCtx(() => [_createVNode(_component_el_backtop, {
          class: "to-top mdi-chevron-up",
          style: {
            "opacity": "1",
            "transform": "scale(1)"
          },
          target: ".el-scrollbar__wrap",
          "visibility-height": 500
        }), _createVNode(_component_Header, {
          title: "律师团队"
        }), _createElementVNode("section", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_cache[2] || (_cache[2] = _createElementVNode("h2", {
          class: "text-center text-divider text-divider-center"
        }, "我们的团队", -1)), _createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(lawyerList.value, item => {
          return _openBlock(), _createElementBlock("div", {
            class: "col-sm-6 col-lg-3",
            key: item.id
          }, [_createElementVNode("div", _hoisted_4, [_createElementVNode("img", {
            class: "person-img",
            style: {
              "object-fit": "contain",
              "width": "100%",
              "max-height": "370px"
            },
            src: item.avatar,
            alt: ""
          }, null, 8, _hoisted_5), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createElementVNode("h3", _hoisted_8, [_createVNode(_component_router_link, {
            to: '/team-member?id=' + item.id
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(item.name) + "律师", 1)]),
            _: 2
          }, 1032, ["to"])]), _cache[0] || (_cache[0] = _createElementVNode("div", {
            class: "person-divider"
          }, null, -1)), _createElementVNode("h5", _hoisted_9, _toDisplayString(item.position || '--'), 1)]), _createElementVNode("div", _hoisted_10, [_createVNode(_component_router_link, {
            to: '/team-member?id=' + item.id,
            class: "person-button"
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [_createElementVNode("span", {
              class: "mdi mdi-arrow-right"
            }, null, -1)])),
            _: 2
          }, 1032, ["to"])])])])]);
        }), 128))])])]), loading.value ? (_openBlock(), _createElementBlock("div", _hoisted_11, "加载中...")) : _createCommentVNode("", true), noMoreData.value ? (_openBlock(), _createElementBlock("div", _hoisted_12, "没有更多数据了")) : _createCommentVNode("", true), _createVNode(_component_Footer)]),
        _: 1
      }, 8, ["onScroll"]);
    };
  }
};