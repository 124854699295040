import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "section rd-navbar-wrap"
};
const _hoisted_2 = {
  class: "rd-navbar rd-navbar-default"
};
const _hoisted_3 = {
  class: "navbar-container"
};
const _hoisted_4 = {
  class: "navbar-cell"
};
const _hoisted_5 = {
  class: "navbar-panel"
};
const _hoisted_6 = {
  class: "navbar-logo"
};
const _hoisted_7 = {
  class: "navbar-cell"
};
const _hoisted_8 = {
  class: "navbar-navigation rd-navbar-nav"
};
const _hoisted_9 = {
  class: "navbar-navigation-root-item"
};
const _hoisted_10 = {
  class: "navbar-navigation-root-item"
};
const _hoisted_11 = {
  class: "navbar-navigation-dropdown rd-navbar-dropdown"
};
const _hoisted_12 = {
  class: "navbar-navigation-dropdown-item"
};
const _hoisted_13 = {
  class: "navbar-navigation-dropdown-item"
};
const _hoisted_14 = {
  class: "navbar-navigation-dropdown-item"
};
const _hoisted_15 = {
  class: "navbar-navigation-dropdown-item"
};
const _hoisted_16 = {
  class: "navbar-navigation-root-item"
};
const _hoisted_17 = {
  class: "navbar-navigation-root-item"
};
const _hoisted_18 = {
  class: "navbar-navigation-root-item"
};
const _hoisted_19 = {
  class: "navbar-navigation-root-item"
};
const _hoisted_20 = {
  class: "navbar-navigation-root-item"
};
const _hoisted_21 = {
  class: "container"
};
const _hoisted_22 = {
  class: "row"
};
const _hoisted_23 = {
  class: "col-md-10 col-xl-8"
};
const _hoisted_24 = {
  class: "page-title-text"
};
export default {
  __name: 'Header',
  props: {
    bg: {
      type: String,
      default: '/images/background/bg1.png'
    },
    title: {
      type: String,
      default: ''
    }
  },
  setup(__props) {
    const props = __props;
    return (_ctx, _cache) => {
      const _component_router_link = _resolveComponent("router-link");
      return _openBlock(), _createElementBlock("section", {
        class: "section page-title bg-image context-dark",
        style: _normalizeStyle({
          'background-image': 'url(' + __props.bg + ')'
        })
      }, [_createElementVNode("header", _hoisted_1, [_createElementVNode("nav", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_cache[1] || (_cache[1] = _createElementVNode("button", {
        class: "navbar-switch linearicons-menu",
        "data-multi-switch": "{\"targets\":\".rd-navbar\",\"scope\":\".rd-navbar\",\"isolate\":\"[data-multi-switch]\"}"
      }, null, -1)), _createElementVNode("div", _hoisted_6, [_createVNode(_component_router_link, {
        class: "navbar-logo-link",
        to: "/"
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [_createElementVNode("img", {
          class: "navbar-logo-default",
          src: "/images/logo-inverse-290x82.png",
          alt: "冀华律所",
          width: "220",
          height: "41"
        }, null, -1), _createElementVNode("img", {
          class: "navbar-logo-inverse",
          src: "/images/logo-inverse-290x82.png",
          alt: "冀华律所",
          width: "220",
          height: "41"
        }, null, -1)])),
        _: 1
      })])])]), _createElementVNode("div", _hoisted_7, [_createElementVNode("ul", _hoisted_8, [_createElementVNode("li", _hoisted_9, [_createVNode(_component_router_link, {
        class: "navbar-navigation-root-link",
        to: "/"
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [_createTextVNode("首页")])),
        _: 1
      })]), _createElementVNode("li", _hoisted_10, [_cache[8] || (_cache[8] = _createElementVNode("a", {
        class: "navbar-navigation-root-link",
        href: "javascript:;"
      }, "关于冀华", -1)), _createElementVNode("ul", _hoisted_11, [_cache[7] || (_cache[7] = _createElementVNode("li", {
        class: "navbar-navigation-back"
      }, [_createElementVNode("button", {
        class: "navbar-navigation-back-btn"
      }, "返回")], -1)), _createElementVNode("li", _hoisted_12, [_createVNode(_component_router_link, {
        class: "navbar-navigation-dropdown-link",
        to: "/about?type=introduction"
      }, {
        default: _withCtx(() => _cache[3] || (_cache[3] = [_createTextVNode("冀华简介")])),
        _: 1
      })]), _createElementVNode("li", _hoisted_13, [_createVNode(_component_router_link, {
        class: "navbar-navigation-dropdown-link",
        to: "/about?type=organization"
      }, {
        default: _withCtx(() => _cache[4] || (_cache[4] = [_createTextVNode("组织机构")])),
        _: 1
      })]), _createElementVNode("li", _hoisted_14, [_createVNode(_component_router_link, {
        class: "navbar-navigation-dropdown-link",
        to: "/about?type=honour"
      }, {
        default: _withCtx(() => _cache[5] || (_cache[5] = [_createTextVNode("资质荣誉")])),
        _: 1
      })]), _createElementVNode("li", _hoisted_15, [_createVNode(_component_router_link, {
        class: "navbar-navigation-dropdown-link",
        to: "/about?type=service"
      }, {
        default: _withCtx(() => _cache[6] || (_cache[6] = [_createTextVNode("服务理念")])),
        _: 1
      })])])]), _createElementVNode("li", _hoisted_16, [_createVNode(_component_router_link, {
        class: "navbar-navigation-root-link",
        to: "/territory"
      }, {
        default: _withCtx(() => _cache[9] || (_cache[9] = [_createTextVNode("业务领域")])),
        _: 1
      })]), _createElementVNode("li", _hoisted_17, [_createVNode(_component_router_link, {
        class: "navbar-navigation-root-link",
        to: "/lawyers"
      }, {
        default: _withCtx(() => _cache[10] || (_cache[10] = [_createTextVNode("专业团队")])),
        _: 1
      })]), _createElementVNode("li", _hoisted_18, [_createVNode(_component_router_link, {
        class: "navbar-navigation-root-link",
        to: "/our-cases"
      }, {
        default: _withCtx(() => _cache[11] || (_cache[11] = [_createTextVNode("冀华观点")])),
        _: 1
      })]), _createElementVNode("li", _hoisted_19, [_createVNode(_component_router_link, {
        class: "navbar-navigation-root-link",
        to: "/service"
      }, {
        default: _withCtx(() => _cache[12] || (_cache[12] = [_createTextVNode("服务单位")])),
        _: 1
      })]), _createElementVNode("li", _hoisted_20, [_createVNode(_component_router_link, {
        class: "navbar-navigation-root-link",
        to: "/contact-us"
      }, {
        default: _withCtx(() => _cache[13] || (_cache[13] = [_createTextVNode("联系我们")])),
        _: 1
      })])])]), _cache[14] || (_cache[14] = _createStaticVNode("<div class=\"navbar-cell\"><div class=\"navbar-subpanel\"><div class=\"navbar-subpanel-item\"><span class=\"navbar-contact-text\">联系我们</span><button class=\"navbar-button navbar-contact-btn navbar-contact-btn-rounded linearicons-menu\" data-multi-switch=\"{&quot;targets&quot;:&quot;.rd-navbar&quot;,&quot;scope&quot;:&quot;.rd-navbar&quot;,&quot;class&quot;:&quot;navbar-contact-active&quot;,&quot;isolate&quot;:&quot;[data-multi-switch]:not(.rd-navbar-contact-btn)&quot;}\"></button><div class=\"navbar-contact\"><ul class=\"list list-sm\"><li class=\"list-item\"><h4 class=\"text-uppercase text-primary\">免费咨询</h4></li><li class=\"list-item\"><a class=\"link link-inherit-primary big2\" href=\"tel:#\">0311-85288005</a></li><li class=\"list-item\"><a class=\"link link-inherit-primary\" href=\"mailto:#\">jihua666@163.com</a></li><li class=\"list-item\"><span>河北省石家庄市<br>桥西区石铜路11号冀华律师楼</span></li></ul></div></div></div></div>", 1))])])]), _createElementVNode("div", _hoisted_21, [_createElementVNode("div", _hoisted_22, [_createElementVNode("div", _hoisted_23, [_createElementVNode("h2", _hoisted_24, _toDisplayString(__props.title), 1)])])])], 4);
    };
  }
};