import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "section-md bg-transparent"
};
const _hoisted_2 = {
  class: "container"
};
const _hoisted_3 = {
  class: "row row-30 justify-content-center justify-content-sm-between"
};
const _hoisted_4 = {
  class: "col-8 col-8 col-md-6 col-lg-5 text-center text-md-right"
};
const _hoisted_5 = {
  class: "image-block"
};
const _hoisted_6 = ["src"];
const _hoisted_7 = {
  class: "col-md-6"
};
const _hoisted_8 = {
  class: "group-5 d-flex flex-wrap align-items-center justify-content-between"
};
const _hoisted_9 = ["innerHTML"];
import { ref, onMounted, computed } from 'vue';
import { useRoute } from 'vue-router';
import { lawyerInfo } from '@/api/team';

import { ElMessage } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/message/style/css';
export default {
  __name: 'team-member',
  setup(__props) {
    const route = useRoute();
    const path = computed(() => {
      return process.env.VUE_APP_BASE_ADDRESS;
    });
    const info = ref(null);
    const getData = async () => {
      if (!route.query.id) {
        return ElMessage.error('参数错误');
      }
      const data = await lawyerInfo(route.query.id);
      if (data) {
        data.avatar = path.value + data.avatar;
        info.value = data;
      }
    };
    onMounted(() => {
      getData();
    });
    return (_ctx, _cache) => {
      const _component_Header = _resolveComponent("Header");
      const _component_Footer = _resolveComponent("Footer");
      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_Header), _createElementVNode("section", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createElementVNode("img", {
        src: info.value?.avatar,
        alt: "",
        width: "300"
      }, null, 8, _hoisted_6)])]), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, [_createElementVNode("h2", null, _toDisplayString(info.value?.name) + "律师", 1)]), _createElementVNode("div", {
        innerHTML: info.value?.content
      }, null, 8, _hoisted_9)])])])]), _createVNode(_component_Footer)]);
    };
  }
};