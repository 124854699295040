/**
 * [cache 本地缓存]
 * 读取：cache(key)
 * 设置：cache(key,value)
 * 删除单个：cache(key,null)
 * 清除所有：cache(null)
 * @param  {[type]} key   [索引]
 * @param  {[type]} value [缓存值]
 * @param  {[type]} time  [有效期(秒)]
 */
export default function cache(key, value, time) {
    if (typeof key != 'undefined' && key != null && typeof value != 'undefined' && value != null) {
        if (typeof time !== 'undefined' && time != null) {
            let expirse = Number(Math.round(new Date() / 1000)) + Number(time);
            let setdata = { value: value, expirse: expirse };
            window.localStorage.setItem(key, JSON.stringify(setdata));
        } else {
            let setdata = { value: value };
            window.localStorage.setItem(key, JSON.stringify(setdata));
        }
    } else if (typeof key !== 'undefined' && key !== null && typeof value === 'undefined') {
        window.localStorage.setItem('ie-edge', JSON.stringify(Number(Math.round(new Date() / 1000))));
        var getvalue = JSON.parse(window.localStorage.getItem(key));
        if (getvalue && getvalue != undefined && getvalue != null) {
            if (getvalue.expirse != undefined && getvalue.expirse != null && getvalue.expirse < Math.round(new Date() / 1000)) {
                window.localStorage.removeItem(key);
                return '';
            } else {
                return getvalue.value;
            }
        }
        return '';
    } else if (typeof key !== 'undefined' && key !== null && typeof value !== 'undefined' && value === null) {
        window.localStorage.removeItem(key);
        window.localStorage.removeItem('ie-edge');
    } else {
        window.localStorage.clear();
    }
}
