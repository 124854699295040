import request from "@/utils/request";

/**
 * 首页banner
 */
export function bannerList(params) {
    return request({
        url: "/api/banner/list",
        method: "get",
        params
    });
}

/**
 * address地址
 */
export function addressList(params) {
    return request({
        url: "/api/address/list",
        method: "get",
        params
    });
}

/**
 * 首页律师
 */
export function lawyersList(params) {
    return request({
        url: "/api/lawyer/listByPage",
        method: "get",
        params
    });
}

/**
 * 获取用户分页列表
 *
 * @param queryParams
 */
export function getUserPage(queryParams) {
    return request({
        url: "/api/users/userListByPage",
        method: "post",
        data: queryParams,
    });
}
